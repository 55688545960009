import React from 'react'
import "../Cards/Cards.css"
import data from "../Cards/Cards.json"
const Cards = () => {
  return (
    <div>

<div className='mapCard_card'>
{data.map((data)=>
<div className="card">
  <img src={data.imgSrc} className="card-img-top imageCard" alt="image"/>
  <div className="card-body">
    <p className="card-text">{data.description}</p>
  </div>
</div>
)}

</div>

    </div>
  )
}

export default Cards