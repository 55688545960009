import React from 'react';
import "../AboutUs/AboutUs.css"
const AboutUs = () => {
  return (
    <div className="container mainContainerAboutUs">
      <div className='subContainer_LeftSideAbouUS'>
      <h5 className="mb-4">Welcome to <strong className='strongtext'>IDCardDori.com</strong>!</h5>
      <p>
        We are your premier online destination for all things related to ID cards, lanyards, files, and folders. At IDCardDori.com, we offer an extensive selection of high-quality products designed to meet the diverse needs of our customers, whether for personal, educational, or professional use.
      </p>
      <h5 className="mt-4">Our Products</h5>
      <ul>
        <li><strong>ID Cards</strong>: We provide a variety of ID cards suitable for schools, businesses, and events. Our cards are customizable to include photos, barcodes, and other essential details.</li>
        <li><strong>Lanyards</strong>: Our collection includes a wide range of lanyards, available in different colors, styles, and materials, ensuring you find the perfect match for your ID cards.</li>
        <li><strong>Files and Folders</strong>: We stock an array of files and folders, ideal for organizing documents at home, school, or office. From simple folders to multi-compartment files, we have it all.</li>
      </ul>
      <h5 className="mt-4">Our Mission</h5>
      <p>
        Our mission at IDCardDori.com is to deliver superior products that enhance organization and identification in everyday life. We are committed to providing exceptional customer service and ensuring a seamless shopping experience for all our customers.
      </p>
      <h5 className="mt-4">About Us</h5>
      <p>
        IDCardDori.com is proudly owned and operated by <strong className='strongtext'>Star Sales</strong>, a trusted name in the industry. With years of experience and a deep understanding of our customers' needs, Star Sales Company is dedicated to maintaining the highest standards of quality and reliability.
      </p>
      <h5 className="mt-4">Why Choose Us?</h5>
      <ul>
        <li><strong>Quality Products</strong>: We ensure that all our products meet stringent quality standards, offering durability and functionality.</li>
        <li><strong>Affordable Prices</strong>: Our competitive pricing ensures that you get the best value for your money.</li>
        <li><strong>Customer Service</strong>: Our dedicated support team is always ready to assist you with any queries or issues, ensuring a smooth and satisfying shopping experience.</li>
        <li><strong>Fast Shipping</strong>: We offer prompt and reliable shipping, so you can receive your products quickly and efficiently.</li>
      </ul>
      <p className="mt-4">
        Explore <strong className='strongtext'>IDCardDori.com</strong> today and discover the perfect solutions for your ID card, lanyard, file, and folder needs. Join our growing community of satisfied customers and experience the best in quality and service.
      </p>
      </div>

      <div className='subContainer_rightside_aboutus'>
        <img src="images/dori.png" alt="image"  className='image1_rightSIde_aboutus'/>
        <img src="images/idCard.png" alt="image"  className='image1_rightSIde_aboutus'/>
        <img src="images/file.png" alt="image"  className='image1_rightSIde_aboutus'/>
      </div>
    </div>
  );
};

export default AboutUs;
