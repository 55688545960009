import React from 'react'
import "../Navbar/Navbar.css"
import { Link } from 'react-router-dom'
const Navbar = () => {
  return (
    <div className='MainConatiner'>

    <div className='subCOntainer_Navbar'>
        <div className='leftSide_Navbar'>
            <h5>IDCardDori.com</h5>
            <p>Owned by Star Sales</p>
        </div>

<div className='rightSide_navbar'>
    <Link to="/" className='link'> <p>Home</p></Link>
    <Link to="/products" className='link'><p>Products</p></Link>
    <Link to="/gallery" className='link'><p>Gallery</p></Link>
</div>



    </div>




    </div>
  )
}

export default Navbar