import React from 'react'
import "../Products/Products.css"
import Cards from '../Cards/Cards'
const Products = () => {
  return (
    <div className='mainContainer_products'>
    
    <Cards/>
    
    
    </div>
  )
}

export default Products