import React from 'react'
import "../Footer/Footer.css"
const Footer = () => {
  return (
    <div className='mainContainer_footer'>
      
      <div className='subcontainer_footer'>

      <div className='leftSidefooter'>
      <ul>
        <h6>Our Vision</h6>
        <li><p>At IDCardDori.com, owned by Star Sales, our vision is to become the leading online destination for high-quality ID cards, lanyards, files, and folders. We aim to provide a seamless shopping experience, offering a wide range of products that meet the diverse needs of our customers with exceptional service and reliability.</p></li>
      </ul>

      </div>



      <div className='rightside_footer'>
      
      <ul>
        <h6>Contact Us</h6>
        <li><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg> 2369/1, Chhatta Shahji, Chawri Bazar, Delhi-110006</p></li>
        <li><p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
</svg> +91-9313280970</p></li>

<li><p>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg> support@idcarddori.com</p>
</li>
      </ul>
       
        


      </div>
      </div>
      <p className='laststatement_footer'>Copyright © 2024-25 IDCardDori.com all rights reserved.</p>


    </div>
  )
}

export default Footer